import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_RB_CARS, GET_RB_SUMMARY } from "./type";
import RBCarList from "../../data/RBCarList";
import cookies from "../../util/cookie";

export function useGetRBSummary() {
  return useQuery(GET_RB_SUMMARY, Api.getRBSummary);
}

export function useGetRBCars() {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    GET_RB_CARS,
    fake
      ? () => ({
          data: RBCarList,
        })
      : Api.getRBCarList
  );
}
