const RBCarList = {
  data: {
    count: 6,
    rented_cars: [
      {
        car_id: 851,
        brand_model: "Exeed LX",
        gos_num: "С474ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        tariff: "Услуги по аренде автомобиля (200 км/сутки)",
        rent_start: "1738140000",
        rent_end: "1738399200",
        rent_days_total: 3,
        rent_days_remain: 3,
        rent_price_day: 3770,
        rent_price_total: 11310,
      },
      {
        car_id: 853,
        brand_model: "Exeed LX",
        gos_num: "С321ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        tariff: "Услуги по аренде автомобиля (200 км/сутки)",
        rent_start: "1737986400",
        rent_end: "1738245600",
        rent_days_total: 3,
        rent_days_remain: 1,
        rent_price_day: 5370,
        rent_price_total: 16110,
      },
      {
        car_id: 854,
        brand_model: "Exeed LX",
        gos_num: "С331ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        tariff: "Услуги по аренде автомобиля (200 км/сутки)",
        rent_start: "1737792000",
        rent_end: "1739347200",
        rent_days_total: 18,
        rent_days_remain: 14,
        rent_price_day: 3107,
        rent_price_total: 55917,
      },
      {
        car_id: 855,
        brand_model: "Exeed LX",
        gos_num: "С315ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        tariff: "Услуги по аренде автомобиля (200 км/сутки)",
        rent_start: "1737986400",
        rent_end: "1738159200",
        rent_days_total: 2,
        rent_days_remain: 0,
        rent_price_day: 3470,
        rent_price_total: 6940,
      },
      {
        car_id: 856,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "С325ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        tariff: "Услуги по аренде автомобиля (200 км/сутки)",
        rent_start: "1737994500",
        rent_end: "1739290500",
        rent_days_total: 15,
        rent_days_remain: 13,
        rent_price_day: 4380,
        rent_price_total: 65700,
      },
    ],
    booked_cars: [
      {
        car_id: 845,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Н659ХС198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        tariff: "Услуги по аренде автомобиля (200 км/сутки)",
        book_start: "1738227600",
        book_end: "1740128400",
        book_days_total: 22,
        book_price_day: 3770,
        book_price_total: 82940,
        book_date: "1738138622",
      },
    ],
  },
};

export default RBCarList;
