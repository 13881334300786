const Notifications = {
  data: [
    {
      notification_id: "1736761427000000012",
      type: "act",
      name: "Просим согласовать акт с 01.12.2024 по 31.12.2024",
      note: "Просим согласовать расчет",
      date: "1736761427",
      read: false,
    },
    {
      notification_id: "1712844811000000012",
      type: "act",
      name: "Просим согласовать акт с 01.03.2024 по 31.03.2024",
      note: "Просим согласовать расчет",
      date: "1712844811",
      read: false,
    },
    {
      notification_id: "1710247029000000012",
      type: "act",
      name: "Просим согласовать акт с 01.02.2024 по 29.02.2024",
      note: "Просим согласовать расчет",
      date: "1710247029",
      read: true,
    },
    {
      notification_id: "1707739963000000012",
      type: "act",
      name: "Просим согласовать акт с 01.01.2024 по 31.01.2024",
      note: "Просим согласовать расчет",
      date: "1707739963",
      read: true,
    },
    {
      notification_id: "1667459536000000012",
      type: "file-shield",
      name: "Кончается ОСАГО",
      note: "У автомобиля кончается ОСАГО через 7 дней - Volkswagen Touareg II С210КС178",
      date: "1667459536",
      read: true,
    },
  ],
};
export default Notifications;
