const Docs = {
  data: [
    {
      doc_id: "000000023",
      type: "report",
      name: "Акт комиссионера с 01.01.2022 по 31.01.2022",
      note: "",
      date_created: "1643662799",
      date_changed: null,
      status: null,
      sum: 105847.2,
      file_link: null,
    },
    {
      doc_id: "000000027",
      type: "report",
      name: "Акт комиссионера с 01.02.2022 по 28.02.2022",
      note: "",
      date_created: "1646081999",
      date_changed: null,
      status: null,
      sum: 77934.7,
      file_link: null,
    },
    {
      doc_id: "000000055",
      type: "report",
      name: "Акт комиссионера с 01.03.2022 по 31.03.2022",
      note: "",
      date_created: "1648717202",
      date_changed: null,
      status: null,
      sum: 45167.7,
      file_link: null,
    },
    {
      doc_id: "000000082",
      type: "report",
      name: "Акт комиссионера с 01.04.2022 по 30.04.2022",
      note: "",
      date_created: "1651309203",
      date_changed: null,
      status: null,
      sum: 73855,
      file_link: null,
    },
    {
      doc_id: "000000105",
      type: "report",
      name: "Акт комиссионера с 01.05.2022 по 31.05.2022",
      note: "",
      date_created: "1653987600",
      date_changed: null,
      status: null,
      sum: 66336.03,
      file_link: null,
    },
    {
      doc_id: "000000157",
      type: "report",
      name: "Акт комиссионера с 01.06.2022 по 30.06.2022",
      note: "",
      date_created: "1656579624",
      date_changed: null,
      status: null,
      sum: 66460.25,
      file_link: null,
    },
    {
      doc_id: "000000164",
      type: "report",
      name: "Акт комиссионера с 01.07.2022 по 31.07.2022",
      note: "",
      date_created: "1659258002",
      date_changed: null,
      status: null,
      sum: 151340.3,
      file_link: null,
    },
    {
      doc_id: "000000773",
      type: "report",
      name: "Акт комиссионера с 01.08.2024 по 31.08.2024",
      note: "",
      date_created: "1725094800",
      date_changed: null,
      status: null,
      sum: 907028.6,
      file_link: null,
    },
    {
      doc_id: "000000792",
      type: "report",
      name: "Акт комиссионера с 01.09.2024 по 30.09.2024",
      note: "Установлено автоматический после 72 часов.",
      date_created: "1727686914",
      date_changed: "1728917638",
      status: "Approved",
      sum: 452446.64,
      file_link: null,
    },
    {
      doc_id: "000000811",
      type: "report",
      name: "Акт комиссионера с 01.10.2024 по 31.10.2024",
      note: "Установлено автоматический после 72 часов.",
      date_created: "1730383526",
      date_changed: "1731596039",
      status: "Approved",
      sum: 572130.4,
      file_link: null,
    },
    {
      doc_id: "000000818",
      type: "report",
      name: "Акт комиссионера с 01.11.2024 по 30.11.2024",
      note: "Установлено автоматический после 72 часов.",
      date_created: "1732976798",
      date_changed: "1734188038",
      status: "Approved",
      sum: 367166.7,
      file_link: null,
    },
    {
      doc_id: "000000829",
      type: "report",
      name: "Акт комиссионера с 01.12.2024 по 31.12.2024",
      note: "Установлено автоматический после 72 часов.",
      date_created: "1735635603",
      date_changed: "1737039240",
      status: "Approved",
      sum: 534409.76,
      file_link: null,
    },
  ],
};

export default Docs;
