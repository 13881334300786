import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_CHART_DATA, GET_ANAL_CARS } from "./type";
import GraphData from "../../data/GraphData";
import cookies from "../../util/cookie";
import AnalTable from "../../data/AnalTable";

export function useGetChartData(from, to, onSuccess) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    [GET_CHART_DATA, from, to],
    fake ? () => ({ data: GraphData }) : () => Api.getChartData(from, to),
    {
      onSuccess,
    }
  );
}

export function useGetAnalCars(from, to) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    [GET_ANAL_CARS, from, to],
    fake ? () => ({ data: AnalTable }) : () => Api.getCarTableData(from, to)
  );
}
