import { useQuery } from "react-query";

//
import Api from "./api";
import {
  GET_CARS_STATUS_COUNT,
  GET_CARS_BY_STATUS,
  GET_CAR_BY_STATUS,
  GET_DASH_CARS,
  GET_CAR_OPERATIONS,
} from "./type";
import DashCars from "../../data/getDashCars";
import cookies from "../../util/cookie";
import CarsStatusesCount from "../../data/CarsStatusesCount";
import CarListByStatusFree from "../../data/CarListByStatusFree";
import CarListByStatusRented from "../../data/CarListByStatusRented";
import CarListByStatusBooked from "../../data/CarListByStatusBooked";
import CarListByStatusOff from "../../data/CarListByStatusOff";
import CarOperations from "../../data/CarOperations";

function getFakeCarsByStatus(type) {
  const data = {
    free: CarListByStatusFree,
    rented: CarListByStatusRented,
    booked: CarListByStatusBooked,
    off: CarListByStatusOff,
  };
  if (type) return { data: data[type] };
}

export function useGetCarsStatusCount() {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    GET_CARS_STATUS_COUNT,
    fake
      ? () => ({
          data: CarsStatusesCount,
        })
      : Api.getCarsStatusesCount
  );
}

export function useGetCarsByStatus(type) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    [GET_CARS_BY_STATUS, type],
    fake ? () => getFakeCarsByStatus(type) : () => Api.getCarsByStatus(type),
    {
      enabled: !!type,
    }
  );
}

export function useGetCarByStatus(type, id) {
  let enabled = !!(type && id);
  return useQuery(
    [GET_CAR_BY_STATUS, type, id],
    () => Api.getCarByStatus(type, id),
    {
      enabled,
    }
  );
}

export function useGetDashboardCars() {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    GET_DASH_CARS,
    fake
      ? () => ({
          data: DashCars,
        })
      : Api.getDashCars
  );
}

export function useGetCarOperations(id, from, to, enabled) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    [GET_CAR_OPERATIONS, id, from, to],
    fake
      ? () => ({ data: CarOperations })
      : () => Api.getCarOperation(id, from, to),
    { enabled }
  );
}
