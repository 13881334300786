import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_DOCS, GET_DOC_BY_ID, GET_ACT } from "./type";
import Docs from "../../data/documents";
import cookies from "../../util/cookie";
import FakeReport from "../../data/report";

export function useGetDocs(options) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    GET_DOCS,
    fake
      ? () => ({
          data: Docs,
        })
      : Api.getDocs,
    {
      ...options,
    }
  );
}

export function useGetDocById(id, date) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    [GET_DOC_BY_ID, id, date],
    fake ? () => ({ data: FakeReport }) : () => Api.getDocById(id, date)
  );
}

export function useGetAct(id, date, options = {}) {
  return useQuery([GET_ACT, id, date], () => Api.getAct(id, date), {
    ...options,
  });
}
