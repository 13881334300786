const FakeUser = {
  data: {
    id: "00004273",
    phone: "79219867080",
    email: "almak@mail.ru",
    address: "194353, Санкт-Петербург г, пр. Маршала Блюхера 12к7",
    change_msg: null,
    avatar: "https://strapi.almak-prokat.ru/uploads/almak_93e8d94af9.png",
    org: ["Альмак Прокат", "78122411048"],
  },
};

export default FakeUser;
