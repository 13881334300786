const GraphData = {
  data: {
    initial_date: "1619859600",
    last_oper_date: "2025-01-29T23:59:59",
    days_total: 1399,
    days_requested: 186,
    profit_total: 3404467,
    profit_current_month: 571285,
    profits_breakes: [
      {
        month: 8,
        year: 2024,
        sum: 907028.6,
        closed: true,
      },
      {
        month: 9,
        year: 2024,
        sum: 452446.64,
        closed: true,
      },
      {
        month: 10,
        year: 2024,
        sum: 572130.4,
        closed: true,
      },
      {
        month: 11,
        year: 2024,
        sum: 367166.7,
        closed: true,
      },
      {
        month: 12,
        year: 2024,
        sum: 534409.76,
        closed: true,
      },
      {
        month: 1,
        year: 2025,
        sum: 571284.64,
        closed: false,
      },
    ],
  },
};

export default GraphData;
