const CarOperations = {
  data: {
    income: 488758,
    expense: 37895,
    period: 182,
    car_id: 783,
    operation_list: [
      {
        operation_type: "bar-line-chart",
        operation_name: "Мониторинг",
        operation_date: 1725137999,
        operation_sum: -400,
        comment: "08.2024",
        operation_details: [
          {
            detail_type: "bar-line-chart",
            detail_name: "Мониторинг(Осн)",
            detail_sum: -400,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Списание ТМЦ",
        operation_date: 1723190410,
        operation_sum: -662,
        comment: null,
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "AddBlue 805 804 мочевина SINTEC",
            detail_sum: -662,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1722762885,
        operation_sum: 8939,
        comment: "03.08.2024 17:00 - 04.08.2024 17:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 1,
            detail_sum: 12770,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -3831,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1723207955,
        operation_sum: 7150.5,
        comment: "08.08.2024 15:00 - 09.08.2024 15:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 1,
            detail_sum: 10215,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -3064.5,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1724308329,
        operation_sum: 75432,
        comment: "12.08.2024 11:00 - 22.08.2024 11:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (500 км/сутки)",
            detail_period: 10,
            detail_sum: 107760,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -32328,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Списание ТМЦ",
        operation_date: 1726574255,
        operation_sum: -679.06,
        comment: null,
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Щетка стеклоочистителя бескаркасная EFM650",
            detail_sum: -453,
            details_file_link: null,
          },
          {
            detail_type: "file-check",
            detail_name: "EFM450 щетка стеклоочистителя ENDUROVISION",
            detail_sum: -226.06,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "bar-line-chart",
        operation_name: "Мониторинг",
        operation_date: 1727729999,
        operation_sum: -400,
        comment: "09.2024",
        operation_details: [
          {
            detail_type: "bar-line-chart",
            detail_name: "Мониторинг(Осн)",
            detail_sum: -400,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1726674314,
        operation_sum: 6979,
        comment: "18.09.2024 13:00 - 19.09.2024 13:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (300 км/сутки)",
            detail_period: 1,
            detail_sum: 9970,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -2991,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "bar-line-chart",
        operation_name: "Мониторинг",
        operation_date: 1730408399,
        operation_sum: -400,
        comment: "10.2024",
        operation_details: [
          {
            detail_type: "bar-line-chart",
            detail_name: "Мониторинг(Осн)",
            detail_sum: -400,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1728896446,
        operation_sum: 94843,
        comment: "27.09.2024 10:30 - 14.10.2024 10:30",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (300 км/сутки)",
            detail_period: 17,
            detail_sum: 135490,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -40647,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1728896456,
        operation_sum: 5342.4,
        comment: "14.10.2024 10:30 - 14.10.2024 12:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (300 км/сутки)",
            detail_period: 0,
            detail_sum: 0,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег постоплата",
            detail_sum: 7632,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -2289.6,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1729099971,
        operation_sum: 12138,
        comment: "14.10.2024 20:30 - 16.10.2024 20:30",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 2,
            detail_sum: 17340,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -5202,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1729443664,
        operation_sum: 10692.5,
        comment: "19.10.2024 15:00 - 20.10.2024 20:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (300 км/сутки)",
            detail_period: 1,
            detail_sum: 14955,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег постоплата",
            detail_sum: 320,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -4582.5,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1729945669,
        operation_sum: 20916,
        comment: "22.10.2024 20:00 - 26.10.2024 20:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 4,
            detail_sum: 29880,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -8964,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "tool",
        operation_name: "Сервис",
        operation_date: 1731767233,
        operation_sum: -21232,
        comment: null,
        operation_details: [
          {
            detail_type: "tool",
            detail_name: "Слесарный ремонт",
            detail_sum: -21232,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "bar-line-chart",
        operation_name: "Мониторинг",
        operation_date: 1733000399,
        operation_sum: -400,
        comment: "11.2024",
        operation_details: [
          {
            detail_type: "bar-line-chart",
            detail_name: "Мониторинг(Осн)",
            detail_sum: -400,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "tool",
        operation_name: "Сервис",
        operation_date: 1732957200,
        operation_sum: -2123.2,
        comment: null,
        operation_details: [
          {
            detail_type: "tool",
            detail_name: "Компенсация ремонта/ТО",
            detail_sum: -2123.2,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1731681680,
        operation_sum: 13692,
        comment: "13.11.2024 19:00 - 15.11.2024 19:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (500 км/сутки)",
            detail_period: 2,
            detail_sum: 19560,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -5868,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1732896351,
        operation_sum: 19327,
        comment: "27.11.2024 9:00 - 30.11.2024 9:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 3,
            detail_sum: 26010,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег",
            detail_sum: 1600,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -8283,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "bar-line-chart",
        operation_name: "Мониторинг",
        operation_date: 1735678799,
        operation_sum: -400,
        comment: "12.2024",
        operation_details: [
          {
            detail_type: "bar-line-chart",
            detail_name: "Мониторинг(Осн)",
            detail_sum: -400,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "tool",
        operation_name: "Сервис",
        operation_date: 1735105337,
        operation_sum: -1560,
        comment: null,
        operation_details: [
          {
            detail_type: "tool",
            detail_name: "Компенсация ремонта/ТО",
            detail_sum: -1560,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Списание ТМЦ",
        operation_date: 1735116343,
        operation_sum: -633,
        comment: null,
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "YUCA230 фильтр салона H1 197",
            detail_sum: -633,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Списание ТМЦ",
        operation_date: 1735116230,
        operation_sum: -806,
        comment: null,
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "AG709 фильтр воздушный H1 197",
            detail_sum: -455,
            details_file_link: null,
          },
          {
            detail_type: "file-check",
            detail_name: "OG152 фильтр масляный H1 197",
            detail_sum: -351,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "tool",
        operation_name: "Сервис",
        operation_date: 1735105232,
        operation_sum: -7800,
        comment: null,
        operation_details: [
          {
            detail_type: "tool",
            detail_name: "Сервисное ТО",
            detail_sum: -7800,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1733047169,
        operation_sum: 6069,
        comment: "30.11.2024 12:30 - 01.12.2024 12:30",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 1,
            detail_sum: 8670,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -2601,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1733574321,
        operation_sum: 21733.6,
        comment: "05.12.2024 9:00 - 09.12.2024 9:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 4,
            detail_sum: 31048,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -9314.4,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1734277635,
        operation_sum: 32884.6,
        comment: "12.12.2024 19:30 - 15.12.2024 19:30",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (500 км/сутки)",
            detail_period: 3,
            detail_sum: 30978,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег",
            detail_sum: 16000,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -14093.4,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1734277645,
        operation_sum: 2168.46,
        comment: "15.12.2024 19:30 - 15.12.2024 22:30",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (500 км/сутки)",
            detail_period: 0,
            detail_sum: 3097.8,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег",
            detail_sum: 0,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -929.34,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1735110504,
        operation_sum: 25627,
        comment: "22.12.2024 9:00 - 25.12.2024 9:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (500 км/сутки)",
            detail_period: 3,
            detail_sum: 32610,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег",
            detail_sum: 4000,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -10983,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1735110514,
        operation_sum: 1601.6,
        comment: "25.12.2024 9:00 - 25.12.2024 9:08",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (500 км/сутки)",
            detail_period: 0,
            detail_sum: 0,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег",
            detail_sum: 0,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег постоплата",
            detail_sum: 2288,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -686.4,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "bar-line-chart",
        operation_name: "Мониторинг",
        operation_date: 1738357199,
        operation_sum: -400,
        comment: "01.2025",
        operation_details: [
          {
            detail_type: "bar-line-chart",
            detail_name: "Мониторинг(Осн)",
            detail_sum: -400,
            details_file_link: null,
          },
        ],
      },
      {
        operation_type: "file-check",
        operation_name: "Реализация",
        operation_date: 1736699642,
        operation_sum: 123780.3,
        comment: "26.12.2024 20:00 - 12.01.2025 20:00",
        operation_details: [
          {
            detail_type: "file-check",
            detail_name: "Услуги по аренде автомобиля (200 км/сутки)",
            detail_period: 17,
            detail_sum: 165869,
            details_file_link: null,
          },
          {
            detail_type: "plus-circle",
            detail_name: "Перепробег постоплата",
            detail_sum: 10960,
            details_file_link: null,
          },
          {
            detail_type: "percent-circle",
            detail_name: "Коммисия",
            detail_sum: -53048.7,
            details_file_link: null,
          },
        ],
      },
    ],
  },
};

export default CarOperations;
