import { useMutation, useQuery } from "react-query";

//
import { GET_USER_INFO } from "./type";
import Api from "./api";
import FakeUser from "../../data/user";
import cookies from "../../util/cookie";

export function useGetUserInfo(options) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";
  return useMutation(
    GET_USER_INFO,
    fake
      ? () => ({
          data: FakeUser,
        })
      : Api.getUserInfo,
    { ...options }
  );
}

export function useGetUserInfoQuery(options) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    GET_USER_INFO,
    fake
      ? () => ({
          data: FakeUser,
        })
      : Api.getUserInfo,
    { ...options }
  );
}
