const MainDashNumbers = {
  data: {
    profit: 809825,
    avg_load: 75,
    saldo: 46040,
    saldo_date: 1738144394,
  },
};

export default MainDashNumbers;
