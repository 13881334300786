const Cars = {
  data: {
    count: 11,
    car_list: [
      {
        car_id: 783,
        brand_model: "Hyundai Grand Starex AT",
        gos_num: "Н193НС198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000101978.jpeg",
        status: "free",
        card_info: {
          run_km: 184593,
          run_date: "2025-01-12T19:34:02",
          engine: "Дизель",
          drive: "Задний",
          year: "2018",
        },
        car_docs: {
          sts: {
            doc_num: "9933325392",
            gos_num: "Н193НС198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "16430 2054188240",
            vin: "KMDDK81KBKU021781",
            year: 2022,
            who: "Система эл",
          },
          osago: {
            date_start: "1708030800",
            date_end: "1739566800",
            num: "ХХХ 0378449090",
            company: "ГАЙДЕ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1735136149",
            run_last: 180492,
            run_next: 195492,
            run_each: 15000,
          },
          history: [
            {
              who: "АБМ",
              date: "1686660635",
              run: 84865,
            },
            {
              who: "Бикар",
              date: "1688205242",
              run: 89550,
            },
            {
              who: "АВМ",
              date: "1692190682",
              run: 98300,
            },
            {
              who: "РайАвто",
              date: "1696168052",
              run: 107410,
            },
            {
              who: "АВМ",
              date: "1702284886",
              run: 116192,
            },
            {
              who: "собственник",
              date: "1707488805",
              run: 129376,
            },
            {
              who: "Собственник",
              date: "1712929843",
              run: 138500,
            },
            {
              who: "Собственник",
              date: "1718193345",
              run: 148820,
            },
            {
              who: "собственник",
              date: "1720886436",
              run: 155600,
            },
            {
              who: "Комиссионер",
              date: "1726499003",
              run: 166350,
            },
            {
              who: "АВМ",
              date: "1735136149",
              run: 180492,
            },
          ],
        },
      },
      {
        car_id: 844,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Н724ХС198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        status: "off",
        card_info: {
          run_km: 47777,
          run_date: "2024-12-18T20:31:40",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957901242",
            gos_num: "Н724ХС198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 065397360",
            vin: "LVVDB21B3PD782018",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723582800",
            date_end: "1755032400",
            num: "ТТТ 7062192810",
            company: "Ренессанс",
          },
        },
        maintenance_log: {
          recent: {
            date: "1732700284",
            run_last: 47214,
            run_next: 62214,
            run_each: 15000,
          },
          history: [
            {
              who: "",
              date: "1692799039",
              run: 10,
            },
            {
              who: "Комиссионер",
              date: "1706535545",
              run: 10500,
            },
            {
              who: "комиссионер",
              date: "1716628446",
              run: 22223,
            },
            {
              who: "комиссионер",
              date: "1719334812",
              run: 26900,
            },
            {
              who: "собственник",
              date: "1723987130",
              run: 36650,
            },
            {
              who: "",
              date: "1732700284",
              run: 47214,
            },
          ],
        },
      },
      {
        car_id: 845,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Н639ХС198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        status: "booked",
        card_info: {
          run_km: 52950,
          run_date: "2025-01-23T18:23:19",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957901240",
            gos_num: "Н639ХС198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 065396953",
            vin: "LVVDB21B1PD781918",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723582800",
            date_end: "1755032400",
            num: "ТТТ 7062193429",
            company: "Ренессанс",
          },
        },
        maintenance_log: {
          recent: {
            date: "1737645799",
            run_last: 52950,
            run_next: 67950,
            run_each: 15000,
          },
          history: [
            {
              who: "",
              date: "1692772976",
              run: 0,
            },
            {
              who: "ТО не делали межсервисный 15к",
              date: "1702981462",
              run: 9874,
            },
            {
              who: "Комиссионер",
              date: "1706534049",
              run: 14300,
            },
            {
              who: "Комиссионер",
              date: "1716568624",
              run: 23430,
            },
            {
              who: "собственник",
              date: "1721233209",
              run: 34000,
            },
            {
              who: "собственик",
              date: "1730799739",
              run: 41040,
            },
            {
              who: "Собственник ",
              date: "1737645799",
              run: 52950,
            },
          ],
        },
      },
      {
        car_id: 846,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Н599ХС198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        status: "free",
        card_info: {
          run_km: 49658,
          run_date: "2025-01-26T19:33:23",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957901069",
            gos_num: "Н599ХС198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 067096178",
            vin: "LVVDB21B4PD767771",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723237200",
            date_end: "1754686800",
            num: "ТТТ 7062193891",
            company: "Ренессанс",
          },
        },
        maintenance_log: {
          recent: {
            date: "1737017946",
            run_last: 49260,
            run_next: 64260,
            run_each: 15000,
          },
          history: [
            {
              who: "",
              date: "1692772957",
              run: 0,
            },
            {
              who: "АВМ",
              date: "1703073140",
              run: 9900,
            },
            {
              who: "Собственник",
              date: "1718298436",
              run: 24050,
            },
            {
              who: "Собственник",
              date: "1724864284",
              run: 36450,
            },
            {
              who: "собственик",
              date: "1730800087",
              run: 40760,
            },
            {
              who: "собственик",
              date: "1737017946",
              run: 49260,
            },
          ],
        },
      },
      {
        car_id: 851,
        brand_model: "Exeed LX",
        gos_num: "С404ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        status: "rented",
        card_info: {
          run_km: 50015,
          run_date: "2025-01-29T09:48:39",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957902867",
            gos_num: "С404ЕВ198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 066813467",
            vin: "LVTDB21B3PE910311",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723842000",
            date_end: "1752613200",
            num: "ХХХ 0429363656",
            company: "СОГАЗ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1730207493",
            run_last: 42436,
            run_next: 57436,
            run_each: 15000,
          },
          history: [
            {
              who: "",
              date: "1693486776",
              run: 10,
            },
            {
              who: "АВМ",
              date: "1707566485",
              run: 14100,
            },
            {
              who: "Комиссионер",
              date: "1719315680",
              run: 28500,
            },
            {
              who: "Комиссионер",
              date: "1730207493",
              run: 42436,
            },
          ],
        },
      },
      {
        car_id: 852,
        brand_model: "Exeed LX",
        gos_num: "С338ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        status: "off",
        card_info: {
          run_km: 59591,
          run_date: "2025-01-12T11:38:03",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957902863",
            gos_num: "С338ЕВ198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 068484686",
            vin: "LVTDB21BXPE910323",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723842000",
            date_end: "1755291600",
            num: "ХХХ 0429355109",
            company: "СОГАЗ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1730799989",
            run_last: 50600,
            run_next: 60600,
            run_each: 10000,
          },
          history: [
            {
              who: "",
              date: "1693487273",
              run: 10,
            },
            {
              who: "АВМ",
              date: "1700662509",
              run: 10250,
            },
            {
              who: "Собственник",
              date: "1708507832",
              run: 19500,
            },
            {
              who: "Собственник на АВМ делал",
              date: "1714404324",
              run: 30000,
            },
            {
              who: "собственик",
              date: "1724224335",
              run: 45600,
            },
            {
              who: "собственик",
              date: "1730799989",
              run: 50600,
            },
          ],
        },
      },
      {
        car_id: 853,
        brand_model: "Exeed LX",
        gos_num: "С301ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        status: "rented",
        card_info: {
          run_km: 42800,
          run_date: "2025-01-26T15:50:42",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957902870",
            gos_num: "С301ЕВ198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 068483730",
            vin: "LVTDB21B9PE910331",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723842000",
            date_end: "1755291600",
            num: "ХХХ 0429363707",
            company: "СОГАЗ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1734102332",
            run_last: 38340,
            run_next: 48340,
            run_each: 10000,
          },
          history: [
            {
              who: "",
              date: "1693487172",
              run: 10,
            },
            {
              who: "Собственик",
              date: "1706164812",
              run: 10000,
            },
            {
              who: "Комиссионер",
              date: "1719398670",
              run: 19348,
            },
            {
              who: "Комиссионер",
              date: "1725432389",
              run: 28900,
            },
            {
              who: "Комиссионер Рисов",
              date: "1734102332",
              run: 38340,
            },
          ],
        },
      },
      {
        car_id: 854,
        brand_model: "Exeed LX",
        gos_num: "С351ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        status: "rented",
        card_info: {
          run_km: 57204,
          run_date: "2025-01-25T09:14:59",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957902868",
            gos_num: "С351ЕВ198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 066815722",
            vin: "LVTDB21B6PE910318",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723842000",
            date_end: "1755291600",
            num: "ХХХ 0429363665",
            company: "СОГАЗ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1730208043",
            run_last: 49060,
            run_next: 64060,
            run_each: 15000,
          },
          history: [
            {
              who: "",
              date: "1693486817",
              run: 10,
            },
            {
              who: "",
              date: "1700230522",
              run: 7600,
            },
            {
              who: "Бикар",
              date: "1701700980",
              run: 10350,
            },
            {
              who: "Собственник",
              date: "1710398213",
              run: 19200,
            },
            {
              who: "собственник",
              date: "1717066418",
              run: 29000,
            },
            {
              who: "Салонный воздушный маслянный авто Рисова делал сам",
              date: "1721926978",
              run: 38950,
            },
            {
              who: "Комиссионер",
              date: "1730208043",
              run: 49060,
            },
          ],
        },
      },
      {
        car_id: 855,
        brand_model: "Exeed LX",
        gos_num: "С375ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        status: "rented",
        card_info: {
          run_km: 45331,
          run_date: "2025-01-18T16:32:52",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957902866",
            gos_num: "С375ЕВ198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 068483080",
            vin: "LVTDB21B7PE910327",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1723842000",
            date_end: "1755291600",
            num: "ХХХ 0429381070",
            company: "СОГАЗ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1730274034",
            run_last: 39000,
            run_next: 54000,
            run_each: 15000,
          },
          history: [
            {
              who: "",
              date: "1693486973",
              run: 10,
            },
            {
              who: "АВМ",
              date: "1700227074",
              run: 9350,
            },
            {
              who: "Собственник",
              date: "1708603075",
              run: 19195,
            },
            {
              who: "Собственник",
              date: "1722358641",
              run: 27800,
            },
            {
              who: "Комиссионер",
              date: "1730274034",
              run: 39000,
            },
          ],
        },
      },
      {
        car_id: 856,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "С225ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        status: "rented",
        card_info: {
          run_km: 35600,
          run_date: "2025-01-27T16:25:21",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957902869",
            gos_num: "С225ЕВ198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 068030045",
            vin: "LVVDB21B2PD770796",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1724446800",
            date_end: "1755896400",
            num: "ХХХ 0430322482",
            company: "СОГАЗ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1736431909",
            run_last: 35600,
            run_next: 45600,
            run_each: 10000,
          },
          history: [
            {
              who: "",
              date: "1693491014",
              run: 10,
            },
            {
              who: "Комиссионер",
              date: "1706277364",
              run: 12315,
            },
            {
              who: "Комиссионер",
              date: "1725019092",
              run: 25800,
            },
            {
              who: "Комиссионер",
              date: "1736431909",
              run: 35600,
            },
          ],
        },
      },
      {
        car_id: 857,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "С360ЕВ198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        status: "free",
        card_info: {
          run_km: 30778,
          run_date: "2025-01-28T09:47:46",
          engine: "Бензин",
          drive: "Передний",
          year: "2023",
        },
        car_docs: {
          sts: {
            doc_num: "9957902865",
            gos_num: "С360ЕВ198",
            year: 2023,
            who: "ГИБДД 1140",
          },
          pts: {
            doc_num: "164302 068031123",
            vin: "LVVDB21B5PD771148",
            year: 2023,
            who: "Система эл",
          },
          osago: {
            date_start: "1724446800",
            date_end: "1755896400",
            num: "ХХХ 0430322517",
            company: "СОГАЗ",
          },
        },
        maintenance_log: {
          recent: {
            date: "1723726343",
            run_last: 22850,
            run_next: 37850,
            run_each: 15000,
          },
          history: [
            {
              who: "",
              date: "1693491429",
              run: 10,
            },
            {
              who: "силами Рисова",
              date: "1720526723",
              run: 13600,
            },
            {
              who: "Комиссионер Рисова",
              date: "1723726343",
              run: 22850,
            },
          ],
        },
      },
    ],
  },
};

export default Cars;
