import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_PARK_CARS, GET_PARK_CAR_LOAD } from "./type";
import Cars from "../../data/cars";
import cookies from "../../util/cookie";

export function useGetParkCars() {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(GET_PARK_CARS, fake ? () => ({ data: Cars }) : Api.getCar);
}

export function useGetParkCarLoad(id) {
  return useQuery([GET_PARK_CAR_LOAD, id], () => Api.getLoad(id), {
    enabled: !!id,
  });
}
