import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";

//
import Input from "../../../../components/input/Input";
import InputPhone from "../../../../components/input-phone/InputPhone";
import Button from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";
import Api from "../../../../store/auth/api";
import Consts from "../../../../util/const";
import SuccessModal from "./SuccessModal";
import { useAppContext } from "../../../../store/Context";
import { useGetUserInfo } from "../../../../store/user/hook";
import cookies from "../../../../util/cookie";

export default function Enter({
  onResetPassword,
  searchParams,
  removeSearchParam,
  reset_success,
}) {
  // context
  const {
    fn: { changeIsAuth },
  } = useAppContext();

  // state
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setIsError] = useState(null);
  const [showSucces, setShowSucces] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [user, setUser] = useState(null);

  // query
  const mutation = useMutation(
    ({ phone, password }) => Api.getToken(phone, password),
    {
      onSuccess,
      onError,
    }
  );
  let user_info_mutation = useGetUserInfo({
    onSuccess: userOnSuccess,
  });

  // watcher
  useEffect(() => {
    if (!reset_success) return;
    user_info_mutation.mutate();
    setShowSucces(true);
    removeSearchParam("success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset_success]);

  // method
  function onLogin(e) {
    e.preventDefault();
    if (phone.length < 11) {
      setPhoneError(true);
      return;
    }
    if (password) {
      mutation.mutate({ phone, password });
    }
  }

  function onSuccess(res) {
    let token = res.data.data.token;
    cookies.setCookie(Consts.APP_TOKEN, token, Consts.EXPIRY_DAYS);
    cookies.setCookie(Consts.USER_PHONE, phone, Consts.EXPIRY_DAYS);
    localStorage.setItem("phone", phone);
    user_info_mutation.mutate();
  }

  function onError(err) {
    setIsError(true);
    if (err.response.status === 500) {
      setIsError("phone");
      return;
    }
    if (err.response.status === 401) {
      setIsError("password");
    }
  }

  function userOnSuccess(res) {
    setUser(res.data.data);
    setShowSucces(true);
  }

  function changePassword(e) {
    resetPasswordErrors();
    setPassword(e.target.value);
  }

  function resetPhoneErrors() {
    setIsError(null);
    setPhoneError(false);
  }

  function resetPasswordErrors() {
    setIsError(null);
  }

  if (showSucces || searchParams.get("success"))
    return (
      <SuccessModal
        changeIsAuth={changeIsAuth}
        user={user}
        removeSearchParam={removeSearchParam}
      />
    );

  return (
    <StyledEnter>
      <div className="login__top">
        <h1>Вход</h1>
        <p>Кабинет партнера</p>
      </div>
      <div className="inputs__group">
        <InputPhone
          value={phone}
          setValue={setPhone}
          fill={1}
          placeholder="+7"
          error={
            error === "phone"
              ? "Пользователь с таким номером не найден"
              : phoneError
              ? "Неверно введен номер: номер должен содержать 10 цифр (формат +7 (xxx) xxx xx xx)"
              : ""
          }
          onResetErr={resetPhoneErrors}
        />
        <Input
          type="password"
          fill={1}
          placeholder="Пароль"
          value={password}
          onChange={changePassword}
          error={error === "password" ? "Неверно указан пароль" : ""}
        />
      </div>
      <div className="actions__group">
        <p onClick={() => onResetPassword("reset", true)}>Сбросить пароль</p>
        <Button
          type="submit"
          color="primary"
          p={[16, 30]}
          textUpperCase={1}
          fW={500}
          onClick={onLogin}
        >
          {(mutation.isLoading || user_info_mutation.isLoading) && (
            <Loader
              radius={16}
              bg="primary"
              color="light"
              w={24}
              h={24}
              size={3}
            />
          )}
          Войти
        </Button>
      </div>
    </StyledEnter>
  );
}

const StyledEnter = styled.form`
  .login__top {
    margin-bottom: 60px;

    h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 37px;
      letter-spacing: 0em;
      text-align: center;
      color: var(--dark);
      text-transform: uppercase;
    }

    p {
      display: none;
    }
  }

  .inputs__group {
    width: 320px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }

  .actions__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--primary);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .login__top {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: 26px;
        line-height: 32px;
      }

      p {
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-50);
      }
    }
  }
`;
