import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_DASH_PROFITS } from "./type";
import MainDashNumbers from "../../data/MainDashNumbers";
import cookies from "../../util/cookie";

export function useGetDashboardProfits() {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    GET_DASH_PROFITS,
    fake ? () => ({ data: MainDashNumbers }) : Api.getDashboardProfitInfos
  );
}
