const CarListByStatusRented = {
  data: {
    count: 5,
    statused_car_list: [
      {
        car_id: 600,
        brand_model: "Haval F7 AT",
        gos_num: "К367УР198",
        status: "rented",
        progress: 25,
      },
      {
        car_id: 746,
        brand_model: "Geely Atlas PRO",
        gos_num: "М614СК198",
        status: "rented",
        progress: 100,
      },
      {
        car_id: 771,
        brand_model: "Omoda C5 AT",
        gos_num: "Н512МО198",
        status: "rented",
        progress: 50,
      },
      {
        car_id: 772,
        brand_model: "Omoda C5 AT",
        gos_num: "Н741МО198",
        status: "rented",
        progress: 71,
      },
      {
        car_id: 781,
        brand_model: "Omoda C5 AT",
        gos_num: "Н656РМ198",
        status: "rented",
        progress: 0,
      },
    ],
  },
};

export default CarListByStatusRented;
