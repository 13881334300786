const CarListByStatusOff = {
  data: {
    count: 2,
    statused_car_list: [
      {
        car_id: 552,
        brand_model: "Haval F7x AT",
        gos_num: "К431ЕМ198",
        status: "off",
        progress: null,
      },

      {
        car_id: 713,
        brand_model: "Geely Coolray",
        gos_num: "М611МХ198",
        status: "off",
        progress: null,
      },
    ],
  },
};

export default CarListByStatusOff;
