const FakeReport = {
	"data": {
		"doc_info": {
			"doc_id": "000000829",
			"type": "report",
			"name": "Отчет по выплатам с 01.12.2024 по 31.12.2024",
			"note": "Установлено автоматический после 72 часов.",
			"date_created": "1735635603",
			"date_changed": "1737039240",
			"status": "Approved",
			"sum": 534409.76,
			"file_link": null
		},
		"month": 12,
		"count": 12,
		"report_car_list": [
			{
				"car_id": 856,
				"brand_model": "Chery Tiggo 7 PRO MAX",
				"gos_num": "С225ЕВ198",
				"income": 49358,
				"expense": 400,
				"comission": 14807.4,
				"pay": 34150.6,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Доп возврат",
						"operation_sum": 0,
						"operation_date": 1734943183,
						"operation_details": []
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 10736.6,
						"operation_date": 1733662795,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (500 км/сутки)",
								"detail_sum": 12938
							},
							{
								"detail_name": "Перепробег",
								"detail_sum": 2400
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -4601.4
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 23814,
						"operation_date": 1734852489,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 34020
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -10206
							}
						]
					}
				]
			},
			{
				"car_id": 857,
				"brand_model": "Chery Tiggo 7 PRO MAX",
				"gos_num": "С360ЕВ198",
				"income": 33216,
				"expense": 400,
				"comission": 9964.8,
				"pay": 22851.2,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 11625.6,
						"operation_date": 1733750043,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 16608
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -4982.4
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 11625.6,
						"operation_date": 1734095967,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 16608
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -4982.4
							}
						]
					}
				]
			},
			{
				"car_id": 846,
				"brand_model": "Chery Tiggo 7 PRO MAX",
				"gos_num": "Н599ХС198",
				"income": 25650,
				"expense": 2400,
				"comission": 7695,
				"pay": 15555,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "tool",
						"operation_name": "Сервис",
						"operation_sum": -2000,
						"operation_date": 1733130387,
						"operation_details": [
							{
								"detail_name": "Возмещение административного штрафа",
								"detail_sum": -2000
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Доп возврат",
						"operation_sum": 0,
						"operation_date": 1733997438,
						"operation_details": []
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 9597,
						"operation_date": 1733305151,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 13710
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -4113
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 8358,
						"operation_date": 1733936365,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 11940
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -3582
							}
						]
					}
				]
			},
			{
				"car_id": 845,
				"brand_model": "Chery Tiggo 7 PRO MAX",
				"gos_num": "Н639ХС198",
				"income": 120690,
				"expense": 400,
				"comission": 36207,
				"pay": 84083,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 43806,
						"operation_date": 1734416884,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (300 км/сутки)",
								"detail_sum": 62580
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -18774
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 40677,
						"operation_date": 1735458631,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (300 км/сутки)",
								"detail_sum": 58110
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -17433
							}
						]
					}
				]
			},
			{
				"car_id": 844,
				"brand_model": "Chery Tiggo 7 PRO MAX",
				"gos_num": "Н724ХС198",
				"income": 17910,
				"expense": 400,
				"comission": 5373,
				"pay": 12137,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 12537,
						"operation_date": 1734543100,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 17910
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -5373
							}
						]
					}
				]
			},
			{
				"car_id": 853,
				"brand_model": "Exeed LX",
				"gos_num": "С301ЕВ198",
				"income": 104990,
				"expense": 1384,
				"comission": 31497,
				"pay": 72109,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Списание ТМЦ",
						"operation_sum": -984,
						"operation_date": 1735108913,
						"operation_details": [
							{
								"detail_name": "XF6045P щетки стеклоочистителя COOLRAY EXEED",
								"detail_sum": -984
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 5698,
						"operation_date": 1733143729,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 8140
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -2442
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 2849,
						"operation_date": 1733228809,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 4070
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -1221
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 2849,
						"operation_date": 1733316995,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 4070
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -1221
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 14637,
						"operation_date": 1733929605,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (300 км/сутки)",
								"detail_sum": 20910
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -6273
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 14637,
						"operation_date": 1734676236,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (300 км/сутки)",
								"detail_sum": 20910
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -6273
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 4879,
						"operation_date": 1734676246,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (300 км/сутки)",
								"detail_sum": 6970
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -2091
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 6083,
						"operation_date": 1734875885,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (300 км/сутки)",
								"detail_sum": 8690
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -2607
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 21861,
						"operation_date": 1735581691,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 31230
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -9369
							}
						]
					}
				]
			},
			{
				"car_id": 852,
				"brand_model": "Exeed LX",
				"gos_num": "С338ЕВ198",
				"income": 53524,
				"expense": 400,
				"comission": 16057.2,
				"pay": 37066.8,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Доп возврат",
						"operation_sum": 1066.8,
						"operation_date": 1733983225,
						"operation_details": [
							{
								"detail_name": "Перепробег постоплата",
								"detail_sum": 1524
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -457.2
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 2779,
						"operation_date": 1733224780,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 3970
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -1191
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 10556,
						"operation_date": 1733927847,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 15080
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -4524
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 12537,
						"operation_date": 1734940511,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 17910
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -5373
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 4179,
						"operation_date": 1734940521,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 5970
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -1791
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 6349,
						"operation_date": 1735404750,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (500 км/сутки)",
								"detail_sum": 9070
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -2721
							}
						]
					}
				]
			},
			{
				"car_id": 854,
				"brand_model": "Exeed LX",
				"gos_num": "С351ЕВ198",
				"income": 73497,
				"expense": 650,
				"comission": 22049.1,
				"pay": 50797.9,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "tool",
						"operation_name": "Сервис",
						"operation_sum": -250,
						"operation_date": 1733130439,
						"operation_details": [
							{
								"detail_name": "Возмещение административного штрафа",
								"detail_sum": -250
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 12534.9,
						"operation_date": 1733551461,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 17907
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -5372.1
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 38913,
						"operation_date": 1735115546,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 55590
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -16677
							}
						]
					}
				]
			},
			{
				"car_id": 855,
				"brand_model": "Exeed LX",
				"gos_num": "С375ЕВ198",
				"income": 65100,
				"expense": 400,
				"comission": 19530,
				"pay": 45170,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 22785,
						"operation_date": 1734436151,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 32550
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -9765
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 22785,
						"operation_date": 1735028722,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 32550
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -9765
							}
						]
					}
				]
			},
			{
				"car_id": 851,
				"brand_model": "Exeed LX",
				"gos_num": "С404ЕВ198",
				"income": 117720,
				"expense": 400,
				"comission": 35316,
				"pay": 82004,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 34335,
						"operation_date": 1733994654,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 49050
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -14715
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 48069,
						"operation_date": 1735556334,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 68670
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -20601
							}
						]
					}
				]
			},
			{
				"car_id": 783,
				"brand_model": "Hyundai Grand Starex AT",
				"gos_num": "Н197НС198",
				"income": 128691.8,
				"expense": 11199,
				"comission": 38607.54,
				"pay": 78885.26,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					},
					{
						"operation_type": "tool",
						"operation_name": "Сервис",
						"operation_sum": -1560,
						"operation_date": 1735105337,
						"operation_details": [
							{
								"detail_name": "Компенсация ремонта/ТО",
								"detail_sum": -1560
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Списание ТМЦ",
						"operation_sum": -633,
						"operation_date": 1735116343,
						"operation_details": [
							{
								"detail_name": "YUCA230 фильтр салона H1 197",
								"detail_sum": -633
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Списание ТМЦ",
						"operation_sum": -806,
						"operation_date": 1735116230,
						"operation_details": [
							{
								"detail_name": "AG709 фильтр воздушный H1 197",
								"detail_sum": -455
							},
							{
								"detail_name": "OG152 фильтр масляный H1 197",
								"detail_sum": -351
							}
						]
					},
					{
						"operation_type": "tool",
						"operation_name": "Сервис",
						"operation_sum": -7800,
						"operation_date": 1735105232,
						"operation_details": [
							{
								"detail_name": "Сервисное ТО",
								"detail_sum": -7800
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 6069,
						"operation_date": 1733047169,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 8670
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -2601
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 21733.6,
						"operation_date": 1733574321,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (200 км/сутки)",
								"detail_sum": 31048
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -9314.4
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 32884.6,
						"operation_date": 1734277635,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (500 км/сутки)",
								"detail_sum": 30978
							},
							{
								"detail_name": "Перепробег",
								"detail_sum": 16000
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -14093.4
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 2168.46,
						"operation_date": 1734277645,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (500 км/сутки)",
								"detail_sum": 3097.8
							},
							{
								"detail_name": "Перепробег",
								"detail_sum": 0
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -929.34
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 25627,
						"operation_date": 1735110504,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (500 км/сутки)",
								"detail_sum": 32610
							},
							{
								"detail_name": "Перепробег",
								"detail_sum": 4000
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -10983
							}
						]
					},
					{
						"operation_type": "file-check",
						"operation_name": "Реализация",
						"operation_sum": 1601.6,
						"operation_date": 1735110514,
						"operation_details": [
							{
								"detail_name": "Услуги по аренде автомобиля (500 км/сутки)",
								"detail_sum": 0
							},
							{
								"detail_name": "Перепробег",
								"detail_sum": 0
							},
							{
								"detail_name": "Перепробег постоплата",
								"detail_sum": 2288
							},
							{
								"detail_name": "Коммисия",
								"detail_sum": -686.4
							}
						]
					}
				]
			},
			{
				"car_id": 784,
				"brand_model": "Volkswagen Touareg III",
				"gos_num": "К548ТО198",
				"income": 0,
				"expense": 400,
				"comission": 0,
				"pay": -400,
				"operation_list": [
					{
						"operation_type": "bar-line-chart",
						"operation_name": "Мониторинг",
						"operation_sum": -400,
						"operation_date": 1735678799,
						"operation_details": [
							{
								"detail_name": "Мониторинг(Осн)",
								"detail_sum": -400
							}
						]
					}
				]
			}
		]
	}
};

export default FakeReport;
