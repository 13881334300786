const CarListByStatusBooked = {
  data: {
    count: 1,
    statused_car_list: [
      {
        car_id: 842,
        brand_model: "Omoda C5 AT",
        gos_num: "Н452ХН198",
        status: "rented",
        progress: 67,
      },
    ],
  },
};

export default CarListByStatusBooked;
