const CarListByStatusFree = {
  data: {
    count: 3,
    statused_car_list: [
      {
        car_id: 631,
        brand_model: "Haval F7 AT",
        gos_num: "К923УР198",
        status: "free",
        progress: null,
      },
      {
        car_id: 680,
        brand_model: "Chery Tiggo 8 PRO AT",
        gos_num: "М811ЕН198",
        status: "free",
        progress: null,
      },
      {
        car_id: 687,
        brand_model: "Haval Jolion AT",
        gos_num: "М731КУ198",
        status: "free",
        progress: null,
      },
    ],
  },
};

export default CarListByStatusFree;
