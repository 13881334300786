/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import Card from "../../../components/card/Card";
import Loader from "../../../components/loader/Loader";
import CardForecast from "../../../components/card-forecast/CardForecast";
import Button from "../../../components/button/Button";
import CarCard from "./components/CarCard";
import AlertIcon from "../../../components/alert-icon/AlertIcon";
import NumberFormat from "../../../components/number-format/NumberFormat";
import EmptyText from "../../../components/epmty-text/EmptyText";
import { useGetRBCars } from "../../../store/rent-book/hook";
import { useGetCarsStatusCount } from "../../../store/car/hook";

//
export default function RentBook() {
  // state
  const [activeTab, setActiveTab] = useState(1);

  // query
  let { data: count, isLoading: loading } = useGetCarsStatusCount();
  count = count?.data?.data;
  let { isLoading: car_loading, data: car } = useGetRBCars();
  car = car?.data?.data;

  let bookedPriceTotal = car?.booked_cars.reduce(
    (acc, currentItem) => acc + currentItem.book_price_total,
    0
  );
  let rentedPriceTotal = car?.rented_cars.reduce(
    (acc, currentItem) => acc + currentItem.rent_price_total,
    0
  );

  // method
  const listForMap = useMemo(() => {
    if (activeTab === 1) return car?.rented_cars || [];
    return car?.booked_cars || [];
  }, [activeTab, car]);

  return (
    <StyleRentBook>
      <Card rentbron mobile={1}>
        <StyleTop>
          <div className="header__amount">
            {loading && <Loader />}
            <section className="header__amount__left">
              <div className="rent__part d__flex">
                <h2>{count?.status_rented_count || 0}</h2>
                <p>Автомобилей в аренде</p>
              </div>
              <div className="bron__part d__flex">
                <h2>{count?.status_booked_count || 0}</h2>
                <p>Автомобилей забронированно</p>
              </div>
            </section>

            <section className="header__amount__right">
              <CardForecast>
                <p className="top__text">Сумма текущих аренд</p>
                <h2 className="number">
                  <NumberFormat value={rentedPriceTotal} />
                </h2>
              </CardForecast>
              <CardForecast warning={1}>
                <p className="top__text">Сумма бронирований</p>
                <h2 className="number">
                  <NumberFormat value={bookedPriceTotal} />
                </h2>
              </CardForecast>
            </section>
          </div>

          <div className="tabs">
            <Button
              p={[14, 24]}
              radius={14}
              color={activeTab === 1 ? "primary" : "light"}
              onClick={() => setActiveTab(1)}
            >
              <p>Аренда</p>
            </Button>
            <Button
              p={[14, 24]}
              radius={14}
              color={activeTab === 0 ? "primary" : "light"}
              onClick={() => setActiveTab(0)}
            >
              <p>Бронирование</p>
            </Button>
          </div>
        </StyleTop>
      </Card>

      <Card rentbron2 mobile={1}>
        {car_loading && <Loader />}
        <StyleBottom>
          <div className="list__header ">
            <div className="list__header__left">Автомобиль</div>
            <div className="list__header__right">
              <div>
                Сумма дохода
                <AlertIcon
                  text="Сумма предполагаемая. В итоге сумма может быть другой."
                  color="dark"
                />
              </div>
              <div>Осталось дней</div>
            </div>
          </div>
          <ul className="car__listt">
            {!listForMap.length ? (
              <EmptyText />
            ) : (
              listForMap.map((i) => (
                <CarCard book={activeTab === 0} car={i} key={i.car_id} />
              ))
            )}
          </ul>
        </StyleBottom>
      </Card>
    </StyleRentBook>
  );
}

const StyleTop = styled.div`
  padding: 30px 30px;

  .header__amount {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 0 30px 30px;
    border-bottom: 1px solid var(--primary-dark-15);
    position: relative;

    &__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      .d__flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;

        & > h2 {
          font-size: 38px;
          font-weight: 600;
          line-height: 46px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        & > p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }
      .rent__part {
        & > p {
          max-width: 106px;
        }
      }
      .bron__part {
        & > p {
          max-width: 126px;
        }
      }
    }
    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;
    }
  }

  .tabs {
    margin-top: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
  }

  @media (max-width: 1200px) {
    .header__amount {
      align-items: initial;
      flex-direction: column;
      justify-content: initial;
      padding: 6px 0 30px 0;
      gap: 36px;
      &__left {
        justify-content: space-around;
      }
      &__right {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }

  @media (max-width: 451px) {
    .header__amount {
      &__left {
        gap: 15px;
        .d__flex {
          & > h2 {
            font-size: 22px;
          }
          & > p {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

const StyleBottom = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 30px 30px 30px;

  .list__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    padding: 38px 95px 38px 30px;
    text-transform: uppercase;

    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 55px;

      & > div {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .car__listt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    .car__parent {
      transition: all 0.2s ease;
      border-radius: 20px;
      overflow: hidden;
      border: 3px solid var(--light);
      background-color: var(--dark-3);

      .car {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 10px 30px 10px 30px;
        background-color: var(--light);
        cursor: pointer;

        &__left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;

          .icon__img {
            i {
              display: none;
            }
            .car__left__img {
              letter-spacing: 0;
              line-height: 0;
              display: inline-block;
            }
          }

          &__name {
            & > p {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0em;
              margin-bottom: 5px;
            }
          }
        }

        &__right {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          .price {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            min-width: 150px;
            text-align: center;
            margin-right: 55px;
          }

          .response__price__day {
            padding: 14px 16px;
            border: 1px solid var(--dark-15);
            border-radius: 14px;

            & > h3 {
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0em;
              color: var(--dark-70);
              margin-top: 5px;
            }
            & > h2 {
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0em;
            }
          }
          .different__day {
            margin-right: 30px;
            border-radius: 14px;
            padding: 16px 45px;
            background-color: var(--dark-5);
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
            min-width: 155px;
            text-align: center;
          }

          .response__defferent__day {
            padding: 14px 16px;
            border: 1px solid var(--dark-15);
            border-radius: 14px;

            & > h3 {
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0em;
              color: var(--dark-70);
              margin-top: 5px;
            }
            & > h2 {
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0em;
            }
          }
          i {
            background-color: var(--dark-70);
          }
        }
      }

      &.active__border {
        border-color: var(--primary);

        .car {
          border-radius: 0 0 20px 20px;
        }
      }

      &:nth-child(2n) {
        .car {
          background-color: transparent;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .car__listt {
      .car__parent {
        .car {
          &__left {
            flex-direction: column-reverse;
            gap: 5px;
            align-items: flex-start;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 20px 0;
    .list__header {
      display: none;
    }

    .car__listt {
      .car__parent {
        .car {
          padding: 20px 30px 30px;
          flex-direction: column;
          gap: 20px;

          &__left {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 10px;

            &__name {
              & > p {
                font-weight: 400;
                /* white-space: nowrap; */
              }
            }
            .icon__img {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: reverse;
              -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
              gap: 10px;

              .car__left__img {
                img {
                  width: 120px;
                }
              }

              i {
                display: inline-block;
                background-color: var(--dark);
              }
            }
          }

          &__right {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 20px;
            width: 100%;

            i {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const StyleRentBook = styled.div`
  .download {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 30px 0;
  }

  .response__download {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 30px 0 110px 0;
    display: none;
  }
  @media (max-width: 768px) {
    .download {
      display: none;
    }
    .response__download {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
`;
