import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//
import Card from "../../../../components/card/Card";
import ProgressBar from "../../../../components/preogress-bar/ProgressBar";
import Button from "../../../../components/button/Button";
import Modal from "../../../../components/modal/Modal";
import StateModal from "../components/StateModal";
import RentModal from "../components/RentModal";
import BookingModal from "../components/BookingModal";
import FreeModal from "../components/FreeModal";
import ServiceModal from "../components/ServiceModal";
import Loader from "../../../../components/loader/Loader";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import {
  useGetCarsStatusCount,
  useGetCarsByStatus,
  useGetCarByStatus,
} from "../../../../store/car/hook";

const titleModal = {
  free: "Свободные автомобили",
  rented: "Автомобили в аренде",
  booked: "Автомобили в бронировании",
  off: "Автомобили в сервисе",
  peregon: "Автомобили в перегоне",
};

export default function StatePark() {
  // helper
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  // state
  const [modal, setModal] = useState("");
  const [currentCar, setCurCar] = useState(null);

  // query
  const { isLoading, data } = useGetCarsStatusCount();
  const car_status_count = data?.data?.data;
  let { isLoading: status_loading, data: status_data } =
    useGetCarsByStatus(modal);

  status_data = status_data?.data?.data;
  let { isLoading: car_loading, data: selected_car } = useGetCarByStatus(
    modal,
    currentCar?.car_id
  );
  selected_car = selected_car?.data?.data;

  // method
  function changeModal(m) {
    setModal(m);
  }

  function changeCurCar(c) {
    setCurCar(c);
  }

  function getPercent(c = 0, max = 0) {
    if (max === 0) return 0;
    return Math.round((c / max) * 100);
  }

  function onCloseModal() {
    changeCurCar(null);
    changeModal("");
  }

  function getModal() {
    const typeObj = {
      free: <FreeModal car={selected_car} />,
      booked: <BookingModal car={selected_car} />,
      rented: <RentModal car={selected_car} />,
      off: <ServiceModal car={selected_car} />,
    };

    return typeObj[modal];
  }

  return (
    <StyledStatePark>
      <main>
        {width < 769 && isLoading && <Loader />}
        <h1 className="state__park__mobile__title">Мой автопарк</h1>
        <section className="state__park__car___count">
          <Card mobile={false}>
            {isLoading && <Loader />}
            <div className="all__card">
              <h1>{car_status_count?.total_count || 0}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    width < 769
                      ? "Всего <br/> автомобилей"
                      : "Всего автомобилей",
                }}
              ></p>
              <div className="btn__wrap">
                <Button onClick={() => navigate("/cabinet/parking")}>
                  В автопарк
                </Button>
              </div>
            </div>
          </Card>
        </section>
        <section className="state__park__car__sort">
          <Card>
            {isLoading && <Loader />}
            <ul>
              <li className="one__card" onClick={() => changeModal("free")}>
                <h1>{car_status_count?.status_free_count || 0}</h1>
                <p>Свободно</p>
                <ProgressBar
                  value={getPercent(
                    car_status_count?.status_free_count,
                    car_status_count?.total_count
                  )}
                  bg="success"
                />
              </li>
              <li className="one__card" onClick={() => changeModal("rented")}>
                <h1>{car_status_count?.status_rented_count || 0}</h1>
                <p>В аренде</p>
                <ProgressBar
                  value={getPercent(
                    car_status_count?.status_rented_count,
                    car_status_count?.total_count
                  )}
                  bg="success"
                />
              </li>
              <li className="one__card" onClick={() => changeModal("booked")}>
                <h1>{car_status_count?.status_booked_count || 0}</h1>
                <p>В Брони</p>
                <ProgressBar
                  value={getPercent(
                    car_status_count?.status_booked_count,
                    car_status_count?.total_count
                  )}
                  bg="success"
                />
              </li>
              <li className="one__card" onClick={() => changeModal("off")}>
                <h1>{car_status_count?.status_off_count || 0}</h1>
                <p>В сервисе</p>
                <ProgressBar
                  value={getPercent(
                    car_status_count?.status_off_count,
                    car_status_count?.total_count
                  )}
                  bg="success"
                />
              </li>
              <li className="one__card" onClick={() => changeModal("peregon")}>
                <h1>{car_status_count?.status_peregon_count || 0}</h1>
                <p>В перегоне</p>
                <ProgressBar
                  value={getPercent(
                    car_status_count?.status_peregon_count,
                    car_status_count?.total_count
                  )}
                  bg="success"
                />
              </li>
            </ul>
          </Card>
        </section>
      </main>
      <Modal
        isOpen={modal}
        title={selected_car?.brand_model ?? titleModal[modal]}
        topSm={1}
        topCurCar={!!currentCar}
        isLoading={status_loading || car_loading}
        carNum={currentCar?.gos_num}
        onClose={onCloseModal}
        changeCurCar={changeCurCar}
      >
        {currentCar ? (
          <>{getModal()}</>
        ) : (
          <>
            <StateModal
              type={modal}
              data={status_data}
              changeCurCar={changeCurCar}
              changeModal={changeModal}
            />
          </>
        )}
      </Modal>
    </StyledStatePark>
  );
}

const StyledStatePark = styled.section`
  & > main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .state__park {
      &__mobile__title {
        display: none;
      }

      &__car___count {
        .all__card {
          padding: 24px 55px;
          text-align: center;
          position: relative;

          h1 {
            font-size: 60px;
            font-weight: 600;
            line-height: 73px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 10px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 20px;
            text-transform: uppercase;
            white-space: nowrap;
          }

          .btn__wrap {
            width: max-content;
            margin: 0 auto;
          }
        }
      }

      &__car__sort {
        ul {
          padding: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          gap: 20px;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          li {
            background: var(--light);
            border: 1px solid var(--primary-dark-15);
            border-radius: 30px;
            padding: 30px 32px;
            text-align: center;
            min-width: 160px;
            cursor: pointer;
            position: relative;

            h1 {
              font-size: 30px;
              font-weight: 600;
              line-height: 37px;
              letter-spacing: 0em;
              color: var(--dark);
              margin-bottom: 10px;
            }

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              color: var(--dark);
              margin-bottom: 15px;
              text-transform: uppercase;
              white-space: nowrap;
            }
          }
        }
      }
    }

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      display: -ms-grid;
      display: grid;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;

      .state__park {
        &__car__sort {
          ul {
            display: -ms-grid;
            display: grid;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            gap: 20px;
            grid-template-areas: "a b" "c d" "e e";

            li {
              padding: 30px;

              &:nth-child(1) {
                grid-area: a;
              }

              &:nth-child(2) {
                grid-area: b;
              }

              &:nth-child(3) {
                grid-area: c;
              }

              &:nth-child(4) {
                grid-area: d;
              }

              &:nth-child(5) {
                grid-area: e;
                text-align: left;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                gap: 10px;

                h1,
                p {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 30px;
      background-color: var(--light);
      gap: 0;
      border-radius: 0px 0px 30px 30px;
      position: relative;
      overflow: hidden;

      .state__park {
        &__mobile__title {
          display: block;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;
          color: var(--dark);
          margin-bottom: 30px;
        }

        &__car___count {
          margin-bottom: 20px;
          .all__card {
            border: 1px solid var(--primary-dark-15);
            border-radius: 20px;
            padding: 20px 20px 20px;
            text-align: left;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            gap: 10px;
            position: relative;

            h1 {
              font-size: 34px;
              line-height: 41px;
              margin-bottom: 0px;
            }

            p {
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 0;
            }

            .btn__wrap {
              width: auto;
              margin: 0;
            }
          }
        }

        &__car__sort {
          ul {
            padding: 0;

            li {
              min-width: auto;
              padding: 20px 15px;
            }
          }
        }
      }
    }

    @media (max-width: 450px) {
      .state__park {
        &__car___count {
          .all__card {
            p {
              width: 75px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
`;
