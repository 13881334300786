const CarsStatusesCount = {
  data: {
    total_count: 11,
    status_free_count: 3,
    status_rented_count: 5,
    status_booked_count: 1,
    status_off_count: 2,
    status_peregon_count: 0,
  },
};

export default CarsStatusesCount;
