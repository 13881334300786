const DashCars = {
  data: {
    count: 11,
    dash_car_list: [
      {
        car_id: 783,
        brand_model: "Hyundai Grand Starex AT",
        gos_num: "Н127НС198",
        profit: 123380,
        load: 39,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000101978.jpeg",
      },
      {
        car_id: 844,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Н324ХС198",
        profit: -400,
        load: 0,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
      },
      {
        car_id: 845,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Н619ХС198",
        profit: -1798,
        load: 0,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
      },
      {
        car_id: 846,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Н593ХС198",
        profit: 69169,
        load: 36,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
      },
      {
        car_id: 851,
        brand_model: "Exeed LX",
        gos_num: "С454ЕВ198",
        profit: 46521,
        load: 79,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
      },
      {
        car_id: 852,
        brand_model: "Exeed LX",
        gos_num: "С368ЕВ198",
        profit: 47351,
        load: 36,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
      },
      {
        car_id: 853,
        brand_model: "Exeed LX",
        gos_num: "С371ЕВ198",
        profit: 29402,
        load: 50,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
      },
      {
        car_id: 854,
        brand_model: "Exeed LX",
        gos_num: "С321ЕВ198",
        profit: 52254,
        load: 61,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
      },
      {
        car_id: 855,
        brand_model: "Exeed LX",
        gos_num: "С373ЕВ198",
        profit: 99581,
        load: 100,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
      },
      {
        car_id: 856,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "С215ЕВ198",
        profit: 34133,
        load: 11,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
      },
      {
        car_id: 857,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "С310ЕВ198",
        profit: 72092,
        load: 43,
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
      },
    ],
  },
};

export default DashCars;
