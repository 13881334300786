const AnalTable = {
  data: {
    count: 11,
    period_m: 1,
    anal_car_list: [
      {
        car_id: 783,
        brand_model: "Hyundai Grand Starex AT",
        gos_num: "Ð197ÐÐ¡198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000101978.jpeg",
        income: 698464.8,
        expense: 37895.26,
        comission: 209706.84,
        pay: 451420.7,
        load: 38,
      },
      {
        car_id: 844,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Ð724Ð¥Ð¡198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        income: 399421,
        expense: 2400,
        comission: 119826.3,
        pay: 277194.7,
        load: 35,
      },
      {
        car_id: 845,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Ð639Ð¥Ð¡198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        income: 379060,
        expense: 7788,
        comission: 113718,
        pay: 257554,
        load: 38,
      },
      {
        car_id: 846,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Ð599Ð¥Ð¡198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        income: 399460,
        expense: 4400,
        comission: 119838,
        pay: 275222,
        load: 32,
      },
      {
        car_id: 851,
        brand_model: "Exeed LX",
        gos_num: "Ð¡404Ð•Ð’198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        income: 517205,
        expense: 2443,
        comission: 155161.5,
        pay: 359600.5,
        load: 62,
      },
      {
        car_id: 852,
        brand_model: "Exeed LX",
        gos_num: "Ð¡338Ð•Ð’198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        income: 373752.2,
        expense: 2446,
        comission: 112125.66,
        pay: 259180.54,
        load: 40,
      },
      {
        car_id: 853,
        brand_model: "Exeed LX",
        gos_num: "Ð¡301Ð•Ð’198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        income: 540128,
        expense: 3384,
        comission: 162038.4,
        pay: 374705.6,
        load: 59,
      },
      {
        car_id: 854,
        brand_model: "Exeed LX",
        gos_num: "Ð¡351Ð•Ð’198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        income: 530207,
        expense: 2696,
        comission: 159062.1,
        pay: 368448.9,
        load: 65,
      },
      {
        car_id: 855,
        brand_model: "Exeed LX",
        gos_num: "Ð¡375Ð•Ð’198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100552.jpeg",
        income: 471107,
        expense: 2446,
        comission: 141332.1,
        pay: 327328.9,
        load: 50,
      },
      {
        car_id: 856,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Ð¡225Ð•Ð’198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        income: 304518,
        expense: 2400,
        comission: 92913.3,
        pay: 214397.7,
        load: 32,
      },
      {
        car_id: 857,
        brand_model: "Chery Tiggo 7 PRO MAX",
        gos_num: "Ð¡360Ð•Ð’198",
        car_pic_url: "https://api.almak.rent:62228/image/KSM000100554.jpeg",
        income: 343176,
        expense: 2400,
        comission: 102952.8,
        pay: 237823.2,
        load: 33,
      },
    ],
  },
};

export default AnalTable;
