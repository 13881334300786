import { useQuery } from "react-query";

//
import NotifApi from "./api";
import { GET_NOTiFICATIONS } from "./type";
import cookies from "../../util/cookie";
import Notifications from "../../data/notification";

export function useGetNotifications(options) {
  const fake = cookies.getCookie("USER_PHONE") === "79219867080";

  return useQuery(
    GET_NOTiFICATIONS,
    fake ? () => ({ data: Notifications }) : NotifApi.getNotifications,
    { ...options }
  );
}
